/* Base tenant styles */

@import "../colors";
@import "../settings";

.kmta__tenant--mainecho {
    .kmta__headerTop {
        padding-top: 10px;
        padding-bottom: 7px;
    }

    &.is-guest .kmta__headerTop {
        padding-top: 15px;
        padding-bottom: 12px;
    }

    .base_svgIcon--logo {
        width: 60px;
        height: 60px;
    }

    .kmta__faqHome {
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#eceded),
            to(#c6c7c8)
        );
        background: linear-gradient(to bottom, #eceded 0%, #c6c7c8 100%);
    }

    .mainecho_teaser {
        background-color: var(
            --f-4-primary-button-bg-color,
            var(--primary-color)
        ) !important;
    }

    .kmta__headerNavWrapper {
        padding-left: 0px;
        margin-left: -20px;
    }

    .svgIcon--tenantLogo {
        height: 44px;
        width: 430px;
        margin-left: -10px;
    }

    .kmta__contactperson.is-expanded {
        height: 250px;
    }
    @include breakpoint(small down) {
        .mainecho__logo {
            width: 100%;
            .svgIcon--tenantLogo {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }
        .kmta__tenantLogoAlt {
            display: none;
        }
        .kmta__headerBottom {
            padding-bottom: 0;
        }
    }
}
